<template>
  <div class="flex w-full flex-col items-center bg-white overflow-hidden">
    <EstimateHeading></EstimateHeading>
    <section v-if="!succeed" class="flex flex-col w-full bg-background-grey">
      <div class="flex flex-col w-full my-2 xl:flex-row">
        <Prompt
          background="1"
          >What is the scope of your project?</Prompt
        >
        <div
          class="flex flex-row sm:items-center justify-center w-full m-auto icons-checkbox-wrapper"
        >
          <CheckBox name="Front-End" v-model="form.scopes.frontend">
          <img
          height="120"
          width="120"
            src="img/estimate/frontend.svg"
            class="filter-pink"
            alt=""
          />
        </CheckBox>
          <CheckBox name="Back-end" v-model="form.scopes.backend">
            <img
            height="120"
            width="120"
              src="img/estimate/backend.svg"
              class="filter-pink"
              alt=""
            /></CheckBox>
          <CheckBox name="Design" v-model="form.scopes.design">
            <img
            height="120"
            width="120"
              src="img/estimate/design.svg"
              class="filter-pink"
              alt=""
            /></CheckBox>
        </div>
      </div>
      <div class="flex flex-col w-full my-2 xl:flex-row">
        <Prompt
          background="2"
          >What is your estimated Time Frame?</Prompt
        >
        <div
          class="flex flex-row sm:items-center justify-center w-full m-auto icons-checkbox-wrapper"
        >
          <Radio name="1 month" v-model="form.time" label="1 month"></Radio>
          <Radio
            name="2-3 months"
            v-model="form.time"
            label="2-3 months"
          ></Radio>
          <Radio name="4+ months" v-model="form.time" label="4+ months"></Radio>
        </div>
      </div>
      <div class="flex flex-col w-full mb-2 form-wrapper xl:flex-row">
        <Prompt
          background="3"
          >Fill the form and get an estimate</Prompt
        >
        <div class="flex flex-col items-center justify-center w-full">
          <div
            class="sm:my-16 sm:my-4 my-0 textarea-input"
          >
            <div class="sm:hidden flex ">
              <div class="mr-3">Contact</div>
              <a
                :href="'mailto:'+$company.contact.email"
                class="text-primary hover:text-primary-dark"
              >
                {{$company.contact.email}}
              </a>
            </div>
            <FormGroupInput
              v-model="form.name"
              :error="field.name"
              placeholder="Full name"
              icon="far fa-user-circle fa-lg"
            ></FormGroupInput>
            <FormGroupInput
              v-model="form.email"
              :error="field.email"
              placeholder="Your email"
              icon="far fa-envelope fa-lg"
            ></FormGroupInput>
            <FormGroupInput
              v-model="form.phone"
              :error="field.phone"
              placeholder="Phone Number"
              icon="fas fa-mobile-alt fa-lg"
            ></FormGroupInput>
            <div class="block">
              <div class="self-start my-3">
                What is your current available budget?
              </div>
              <Budget v-model="form.budget" label="Under 50,000 INR"
                >Under 50,000 INR</Budget
              >
              <Budget v-model="form.budget" label="50,000 INR to 5,00,000 INR"
                >50,000 INR to 5,00,000 INR</Budget
              >
              <Budget v-model="form.budget" label="5,00,000 INR to  15,00,000 INR"
                >5,00,000 INR to  15,00,000 INR</Budget
              >
              <Budget v-model="form.budget" label="Over 15,00,000 INR"
                >Over 15,00,000 INR</Budget
              >
              <Budget
                v-model="form.budget"
                label="I don't want to disclose this now"
                >I don't want to disclose this now</Budget
              >
            </div>
            <div class="self-start sm:mt-12 mt-2">
              Tell us about your product concept
            </div>
            <div rows="7" class="mt-4 textarea-estimation">
              <label for="textarea" class="sr-only">
                Message
              </label>
              <div class="el-textarea">
                <textarea
                  autocomplete="off"
                  id="textarea"
                  placeholder="How can we help ?"
                  rows="7"
                  name="message"
                  class="el-textarea__inner"
                  style="min-height: 106px; height: 106px;"
                  v-model="form.message"
                ></textarea>
              </div>
            </div>
            <span
              v-if="field.message"
              class="error self-start text-primary text-xs pt-1 text-primary"
              >{{ field.message }}</span
            >
            <button
              @click="onSubmit()"
              class="btn w-full font-semibold text-base mt-4 primary btn-default"
            >
              Submit
            </button>
            <div class="flex flex-col justify-center items-center">
              <span
                v-if="error"
                class="error self-start text-primary text-xs pt-1 text-primary"
                >{{error}}. please try later or just reach us via our mail</span
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-else class="min-h-50 flex flex-col justify-center items-center w-full bg-background-grey">
          <h1>Thank you for requesting an estimate. We will send you the estimate shortly.</h1>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import qs from "querystring";
import EstimateHeading from "@/views/subComponents/EstimateHeading.vue";
import { CheckBox, Prompt, Radio, Budget, FormGroupInput } from "@/components";

export default {
  components: {
    EstimateHeading,
    CheckBox,
    Prompt,
    Radio,
    FormGroupInput,
    Budget
  },
  data() {
    return {
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      },
      succeed:false,
      error: "",
      field: {
        name: "",
        email: "",
        phone: "",
        message: ""
      },
      form: {
        budget: "50,000 INR to 5,00,000 INR",
        time: "4+ months",
        scopes: {
          frontend: true,
          backend: false,
          design: false
        },
        name: "",
        email: "",
        phone: "",
        message: ""
      }
    };
  },
  methods: {
    onSubmit() {
      if (this.validateEmail(this.form.email)) {
        this.field.email = ""
        if (this.form.name) {
          this.field.name = ""
          if (this.form.message) {
            this.field.message = ""
            axios
              .post(
              this.$company.formLink,
                qs.stringify({
                  id: "estimate",
                  budget:this.form.budget,
                  name: this.form.name,
                  scopes:Object.keys(this.form.scopes).filter(key => this.form.scopes[key]).join(','),
                  email: this.form.email,
                  phone: this.form.phone,
                  message: this.form.message
                }),
                this.config
              )
              .then(() => {
                this.field.name = "";
                this.field.email = "";
                this.field.message = "";
                this.field.phone = "";
                this.form.name = "";
                this.form.email = "";
                this.form.message = "";
                this.form.phone = "";
                this.form.scopes.frontend = false;
                this.form.scopes.backend = false;
                this.form.scopes.design = false;
                this.succeed = true;
              })
              .catch(err => {
                this.field.name = "";
                this.field.email = "";
                this.field.message = "";
                this.field.phone = "";
                this.error = err.message;
                this.form.scopes.frontend = false;
                this.form.scopes.backend = false;
                this.form.scopes.design = false;
              });
          } else {
            this.field.message = "This field is required";
          }
        } else {
          this.field.name = "The name field is required";
        }
      } else {
        if (this.field.email === "") {
          this.field.email = "The email field is required";
        } else {
          this.field.email = "The email field must be a valid email";
        }
      }
    },
    validateEmail(email) {
      /* eslint-disable */
      var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>

<style lang="css" scoped>
.filter-pink{
    filter: invert(65%) sepia(96%) saturate(2603%) hue-rotate(319deg) brightness(99%) contrast(104%);
}
</style>
